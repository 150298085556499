// import { showInfoMsg, showErrorMsg } from '@/utils/popInfo'
import ElementUI from 'element-ui';
import { getToken } from '@/utils/auth'
var localThis = null;
var setIntervalT = null;
function initWebSocket(t) {
  if(t){
    localThis = t;
  }
  var userId = localThis.$store.state.user.id;
  var tenantCode = localThis.$store.state.user.tenantCode;
  var token = getToken();
  console.log(userId)
  if(token!=null){
    const wsUri = process.env.VUE_APP_WEBSOCKET_URL + "/websocket/"+userId+"/" + tenantCode+"/"+token;
    this.socket = new WebSocket(wsUri)//这里面的this都指向vue
    this.socket.onerror = webSocketOnError;
    this.socket.onmessage = webSocketOnMessage;
    this.socket.onclose = closeWebsocket;
  }

}
function webSocketOnError(e) {
  /***
  ElementUI.Notification({
    title: '',
    message: "WebSocket连接发生错误" + e,
    type: 'error',
    duration: 0,
  });***/
}
function webSocketOnMessage(e) {
  console.log(e.data);
  if(setIntervalT!=null){
    clearInterval(setIntervalT);
  }
}
// 关闭websiocket
function closeWebsocket() {
  console.log('连接已关闭...')
  if(setIntervalT==null){
    setIntervalT = setInterval(initWebSocket,10000);
  }
}
function close() {
  this.socket.close() // 关闭 websocket
  this.socket.onclose = function (e) {
    console.log(e)//监听关闭事件
    console.log('关闭')
  }
}
function webSocketSend(agentData) {
  this.socket.send(agentData);
}


export default {
  initWebSocket, close
}
