<template>
  <div class="login-container flex main">
    <div class="flex1 pr">
      <div class="left">
<!--        <img src="../../assets/images/element.svg" style="width:50%;height:auto" alt="">-->
<!--        <p>亿博服务平台</p>-->
      </div>
    </div>
    <div class="flex1 pr right">
      <el-form ref="loginForm" :model="loginForm" :rules="loginRules" class="login-form" autocomplete="on" label-position="left">

        <div class="title-container">
          <h3 class="title">账户密码登录</h3>
        </div>

        <el-form-item prop="username">
          <el-input
            v-model="loginForm.username"
            placeholder="请输入用户名"
            type="text"
            autocomplete="on"
          >
            <template slot="suffix">
              <span class="svg-container">
                <svg-icon icon-class="user" />
              </span>
            </template>
          </el-input>

        </el-form-item>

        <el-tooltip v-model="capsTooltip" content="Caps lock is On" placement="right" manual>
          <el-form-item prop="password">
            <el-input
              :key="passwordType"
              ref="password"
              v-model="loginForm.password"
              :type="passwordType"
              placeholder="请输入密码"
              name="password"
              autocomplete="on"
              @keyup.native="checkCapslock"
              @blur="capsTooltip = false"
              @keyup.enter.native="handleLogin"
            >
              <template slot="suffix">
                <span class="svg-container">
                  <svg-icon icon-class="password" />
                </span>
              </template>
            </el-input>
          </el-form-item>
        </el-tooltip>
        <el-checkbox v-model="remember">记住密码</el-checkbox>
        <el-button :loading="loading" size="medium" type="primary" block class="button" @click.native.prevent="handleLogin">立即登录</el-button>
        <!-- <div class="tipsView">
          <div>还没有账号？</div>
          <el-button style="font-size:14px;" type="text" @click="onRouter">去注册</el-button>
        </div> -->
      </el-form>

    </div>

  </div>
</template>

<script>

import { loadEnum } from '@/utils/enum'

export default {
  name: 'Login',
  data() {
    const validateemail = (rule, value, callback) => {
      var reg = /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})$/
      if (value.trim() === '') {
        callback(new Error('Email不能为空'))
      } else {
        if (reg.test(value)) {
          callback()
        } else {
          callback(new Error('Email格式不正确'))
        }
      }
    }
    const validatePassword = (rule, value, callback) => {
      if (value.trim() === '') {
        callback(new Error('密码不能为空'))
      } else {
        callback()
      }
    }
    return {
      loginForm: {
        email: '',
        password: ''
      },
      loginRules: {
        email: [{ required: true, trigger: ['blur', 'change'], validator: validateemail }],
        password: [{ required: true, trigger: ['blur', 'change'], validator: validatePassword }],
        username: [{ required: true, message: '请输入用户名', trigger: ['blur', 'change'] }]
      },
      passwordType: 'password',
      capsTooltip: false,
      loading: false,
      showDialog: false,
      redirect: undefined,
      remember: false,
      otherQuery: {}
    }
  },
  watch: {
    $route: {
      handler: function(route) {
        const query = route.query
        if (query) {
          this.redirect = query.redirect
          this.otherQuery = this.getOtherQuery(query)
        }
      },
      immediate: true
    }
  },
  created() {
    // 在页面加载时从cookie获取登录信息
    const email = this.$cookie.get('email')
    const password = this.$cookie.get('password')
    if (email) {
      this.loginForm.email = email
      this.loginForm.password = password
      this.remember = true
    }
    // window.addEventListener('storage', this.afterQRScan)
  },
  mounted() {
    if (this.loginForm.email === '') {
      // this.$refs.email.focus()
    } else if (this.loginForm.password === '') {
      // this.$refs.password.focus()
    }
  },
  destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
  },
  methods: {
    onRouter() {
      this.$router.replace({
        path: '/register'
      })
    },
    checkCapslock(e) {
      const { key } = e
      this.capsTooltip = key && key.length === 1 && (key >= 'A' && key <= 'Z')
    },
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    // 储存表单信息
    setUserInfo: function() {
      // 判断用户是否勾选记住密码，如果勾选，向cookie中储存登录信息，
      // 如果没有勾选，储存的信息为空
      if (this.remember) {
        this.$cookie.set('email', this.loginForm.email, 7)
        const passWord = this.loginForm.password
        this.$cookie.set('password', passWord, 7)
      } else {
        this.$cookie.set('email', '')
        this.$cookie.set('password', '')
      }
    },
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.setUserInfo()
          this.$store.dispatch('user/login', this.loginForm)
            .then(() => {
              // this.$router.push({ path: this.redirect || '/', query: this.otherQuery })
              this.$router.push({ path: '/index' })
              this.loading = false
              // 加载枚举信息
              loadEnum()
            })
            .catch(() => {
              this.loading = false
            })
        } else {
          return false
        }
      })
    },
    getOtherQuery(query) {
      return Object.keys(query).reduce((acc, cur) => {
        if (cur !== 'redirect') {
          acc[cur] = query[cur]
        }
        return acc
      }, {})
    }
  }
}
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg:#283443;
$light_gray:#fff;
$cursor: #fff;

/* reset element-ui css */
.login-container {
  //background: url("../../assets/images/login.svg");
  background-image: url("../../assets/images/login-background-ERP.png");
  background-size: cover;
  .el-input {
    display: inline-block;
  }

  .el-form-item {
    // border: 1px solid rgba(255, 255, 255, 0.1);
    // background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    // color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bg:#2d3a4b;
$dark_gray:#889aa4;
$light_gray:#eee;
.login-container {
  min-height: 100%;
  width: 100%;
  // background-color: $bg;
  overflow: hidden;
  .left {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    transform: translateY(-50%);
    margin: 0 auto;
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
    font-size: 21.355072463768114px;
  }
  .login-form {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    bottom: 0;
    transform: translateY(-50%);
    width: 240px;
    margin: 0 auto;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }
  ::v-deep .el-input__suffix{
    margin-right: 10px;
    margin-top: 4px;
  }
  // .svg-container {
  //   padding: 6px 5px 6px 15px;
  //   color: $dark_gray;
  //   vertical-align: middle;
  //   width: 30px;
  //   display: inline-block;
  // }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: left;
      font-weight: bold;
      position: relative;
      color: rgba(25, 137, 250, 1);
      font-size: 18px;
      line-height: 24px;
      &:after{
        content: ' ';
        position: absolute;
        left: 0;
        bottom: -8px;
        width: 96px;
        height: 2px;
        background-color: rgba(25, 137, 250, 1);
      }
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }

  .thirdparty-button {
    position: absolute;
    right: 0;
    bottom: 6px;
  }
  .button{
    width: 100%;
    display: block;
    margin-top: 25px;
  }

  @media only screen and (max-width: 470px) {
    .thirdparty-button {
      display: none;
    }
  }
}
.tipsView{
  font-size:14px;
  display:flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
