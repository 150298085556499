import defaultSettings from '@/settings'

const { aesKey } = defaultSettings

const CryptoJS = require('crypto-js')

// aes加密工具类
export const aes = {
  // 加密函數
  encrypt: (word) => {
    if (word instanceof Object) {
      word = JSON.stringify(word)
    }
    const key = CryptoJS.enc.Utf8.parse(aesKey)
    const encryptedObj = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(word), key,
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }
    )
    return encryptedObj.toString()
  },
  // 解密函數
  decrypt: (word) => {
    const key = CryptoJS.enc.Utf8.parse(aesKey)
    const decrypt = CryptoJS.AES.decrypt(word, key,
      {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.ZeroPadding
      }
    )
    return CryptoJS.enc.Utf8.stringify(decrypt).toString()
  }
}
