<template>
  <div class="container">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <el-button
          @click="onRouter"
          style="float: right; padding: 3px 0"
          type="text"
          >返回登录</el-button
        >
      </div>
      <div class="content" v-if="!isSubmit">
        <div class="custom-steps">
          <div class="custom-step">
            <div class="custom-step-icon">1</div>
            <span>填写资料</span>
          </div>
          <div class="custom-step custom-step-gray">
            <div class="custom-step-icon">2</div>
            <span>提交审核</span>
          </div>
        </div>
        <el-row :gutter="0">
          <el-form
            ref="elForm"
            :model="formData"
            :rules="rules"
            size="small"
            label-width="120px"
          >
            <el-col :span="24">
              <el-form-item>
                <div class="form-title">公司信息</div>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="上传营业执照" prop="businessLicense">
                <el-upload
                  :action="uploadUrl"
                  list-type="picture-card"
                  :on-success="handleAvatarSuccess"
                >
                </el-upload>
              </el-form-item>
            </el-col>
            <!-- <el-col :span="24">
          <el-upload
            action="https://jsonplaceholder.typicode.com/posts/"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
          >
            <i class="el-icon-plus"></i>
          </el-upload>
        </el-col> -->
            <el-col :span="12">
              <el-form-item label="公司名称" prop="companyName">
                <el-input
                  v-model="formData.companyName"
                  placeholder="请输入公司名称"
                  clearable
                  :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="公司电话" prop="companyMobile">
                <el-input
                  v-model="formData.companyMobile"
                  placeholder="请输入公司电话"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="公司地址" prop="companyAddress">
                <el-input
                  v-model="formData.companyAddress"
                  placeholder="请输入公司地址"
                  clearable
                  :style="{ width: '100%' }"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item>
                <div class="form-title">联系人信息</div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系人姓名" prop="name">
                <el-input
                  v-model="formData.name"
                  placeholder="请输入联系人姓名"
                  clearable
                  :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系人手机号码" prop="mobile">
                <el-input
                  v-model="formData.mobile"
                  placeholder="请输入联系人手机号码"
                  clearable
                  :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="联系人邮箱" prop="email">
                <el-input
                  v-model="formData.email"
                  placeholder="请输入联系人邮箱"
                  clearable
                  :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="座机" prop="specialPlane">
                <el-input
                  v-model="formData.specialPlane"
                  placeholder="请输入座机"
                  clearable
                  :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="微信号" prop="WechatId">
                <el-input
                  v-model="formData.WechatId"
                  placeholder="请输入微信号"
                  clearable
                  :style="{ width: '100%' }"
                >
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="24">
              <el-form-item label="公众号" prop="WechatId">
                <span> 扫码下方二维码，获取更多资讯 </span>
                <div class="qrcode"></div>
              </el-form-item>
            </el-col>
          </el-form>
        </el-row>
        <el-row type="flex" justify="center">
          <el-button
            style="margin: 0 auto"
            size="mini"
            type="primary"
            @click="submitForm"
            :loading="isLoading"
            >下一步</el-button
          >
        </el-row>
      </div>
      <div class="content" v-if="isSubmit">
        <el-result
          icon="success"
          title="注册资料提交中，请耐心等待"
          subTitle="系统正在对你的资料审核，大约需要3-15秒，请不要关闭页面"
        >
        </el-result>
      </div>
    </el-card>
  </div>
</template>
<script>
import { register } from "@/api/jsb/user";
import { uploadFile } from "@/utils/file";
export default {
  components: {},
  props: [],
  data() {
    return {
      isSubmit: false,
      isLoading: false,
      formData: {
        businessLicense: undefined, //营业执照
        companyName: undefined, //公司名称
        companyMobile: undefined, //公司电话
        companyAddress: undefined, //公司地址
        name: undefined, //联系人姓名
        mobile: undefined, //联系人手机号码
        email: undefined, //联系人邮箱
        specialPlane: undefined, //座机
        WechatId: undefined, //微信号
      },
      rules: {
        businessLicense: [
          {
            required: true,
            message: "请上传营业执照",
            trigger: "blur",
          },
        ],
        companyName: [
          {
            required: true,
            message: "请输入公司名称",
            trigger: "blur",
          },
        ],
        companyMobile: [
          {
            required: true,
            message: "请输入公司电话",
            trigger: "blur",
          },
        ],
        companyAddress: [
          {
            required: true,
            message: "请输入公司地址",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入联系人姓名",
            trigger: "blur",
          },
        ],
        mobile: [
          {
            required: true,
            message: "请输入联系人手机号码",
            trigger: "blur",
          },
        ],
        email: [],
        specialPlane: [],
        WechatId: [],
      },
    };
  },
  computed: {
    uploadUrl() {
      return process.env.VUE_APP_FILE_UPLOAD_URL;
    },
  },
  watch: {},
  created() {},
  mounted() {},
  methods: {
    handleAvatarSuccess(response) {
      if (!response.status === 200) {
        this.$message.error(response.msg);
        return;
      }
      const { fileName, url, md5 } = response.data;
      this.formData.businessLicense = url;
      console.log(fileName, url, md5);
    },
    submitForm() {
      this.$refs["elForm"].validate(async (valid) => {
        if (!valid) return;
        // TODO 提交表单
        try {
          this.isLoading = true;
          const data = await register(this.formData);
          this.isLoading = false;
          this.isSubmit = true;
          setTimeout(() => {
            this.onRouter();
          }, 3000);
        } catch (error) {
          this.isLoading = false;
        }
        console.log(data);
      });
    },
    resetForm() {
      this.$refs["elForm"].resetFields();
    },
    onRouter() {
      this.$router.replace({
        path: "/login",
      });
    },
  },
};
</script>
<style scoped>
.container {
  margin: 0 auto;
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;
}
.navBar {
  height: 50px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 1px solid #aaaaaa;
  padding: 0 50px;
  box-sizing: border-box;
}
.content {
  padding: 0 50px 50px 50px;
  box-sizing: border-box;
}
.custom-steps {
  display: flex;
  flex-flow: row nowrap;
  padding: 10px 10px 20px 10px;
  box-sizing: border-box;
  border-bottom: 1px solid #aaaaaa;
  margin-bottom: 50px;
}
.custom-step {
  margin-right: 50px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  font-size: 14px;
}
.custom-step-gray {
  opacity: 0.2;
}
.custom-step-icon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #8400ff;
  color: #fff;
}
.form-title {
  font-size: 18px;
  font-weight: bold;
}
.qrcode {
  width: 100px;
  height: 100px;
  background: red;
}
.successView {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}
.icon {
  font-size: 42px;
  color: #70b603;
}
</style>
