var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: "重置密码",
            visible: _vm.dialogVisible,
            width: "30%",
            "show-close": false,
            center: "",
            "close-on-press-escape": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("span", { staticStyle: { color: "red" } }, [
            _vm._v("您的密码已过期，请重置密码"),
          ]),
          _c("reset-password", {
            attrs: { user: _vm.user, callback: _vm.callback },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }