var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c("el-card", { staticClass: "box-card" }, [
        _c(
          "div",
          {
            staticClass: "clearfix",
            attrs: { slot: "header" },
            slot: "header",
          },
          [
            _c(
              "el-button",
              {
                staticStyle: { float: "right", padding: "3px 0" },
                attrs: { type: "text" },
                on: { click: _vm.onRouter },
              },
              [_vm._v("返回登录")]
            ),
          ],
          1
        ),
        !_vm.isSubmit
          ? _c(
              "div",
              { staticClass: "content" },
              [
                _c("div", { staticClass: "custom-steps" }, [
                  _c("div", { staticClass: "custom-step" }, [
                    _c("div", { staticClass: "custom-step-icon" }, [
                      _vm._v("1"),
                    ]),
                    _c("span", [_vm._v("填写资料")]),
                  ]),
                  _c("div", { staticClass: "custom-step custom-step-gray" }, [
                    _c("div", { staticClass: "custom-step-icon" }, [
                      _vm._v("2"),
                    ]),
                    _c("span", [_vm._v("提交审核")]),
                  ]),
                ]),
                _c(
                  "el-row",
                  { attrs: { gutter: 0 } },
                  [
                    _c(
                      "el-form",
                      {
                        ref: "elForm",
                        attrs: {
                          model: _vm.formData,
                          rules: _vm.rules,
                          size: "small",
                          "label-width": "120px",
                        },
                      },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c("el-form-item", [
                              _c("div", { staticClass: "form-title" }, [
                                _vm._v("公司信息"),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "上传营业执照",
                                  prop: "businessLicense",
                                },
                              },
                              [
                                _c("el-upload", {
                                  attrs: {
                                    action: _vm.uploadUrl,
                                    "list-type": "picture-card",
                                    "on-success": _vm.handleAvatarSuccess,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "公司名称",
                                  prop: "companyName",
                                },
                              },
                              [
                                _c("el-input", {
                                  style: { width: "100%" },
                                  attrs: {
                                    placeholder: "请输入公司名称",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.formData.companyName,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "companyName", $$v)
                                    },
                                    expression: "formData.companyName",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "公司电话",
                                  prop: "companyMobile",
                                },
                              },
                              [
                                _c("el-input", {
                                  style: { width: "100%" },
                                  attrs: {
                                    placeholder: "请输入公司电话",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.formData.companyMobile,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "companyMobile",
                                        $$v
                                      )
                                    },
                                    expression: "formData.companyMobile",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "公司地址",
                                  prop: "companyAddress",
                                },
                              },
                              [
                                _c("el-input", {
                                  style: { width: "100%" },
                                  attrs: {
                                    placeholder: "请输入公司地址",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.formData.companyAddress,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "companyAddress",
                                        $$v
                                      )
                                    },
                                    expression: "formData.companyAddress",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c("el-form-item", [
                              _c("div", { staticClass: "form-title" }, [
                                _vm._v("联系人信息"),
                              ]),
                            ]),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "联系人姓名", prop: "name" } },
                              [
                                _c("el-input", {
                                  style: { width: "100%" },
                                  attrs: {
                                    placeholder: "请输入联系人姓名",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.formData.name,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "name", $$v)
                                    },
                                    expression: "formData.name",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "联系人手机号码",
                                  prop: "mobile",
                                },
                              },
                              [
                                _c("el-input", {
                                  style: { width: "100%" },
                                  attrs: {
                                    placeholder: "请输入联系人手机号码",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.formData.mobile,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "mobile", $$v)
                                    },
                                    expression: "formData.mobile",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "联系人邮箱", prop: "email" } },
                              [
                                _c("el-input", {
                                  style: { width: "100%" },
                                  attrs: {
                                    placeholder: "请输入联系人邮箱",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.formData.email,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "email", $$v)
                                    },
                                    expression: "formData.email",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: { label: "座机", prop: "specialPlane" },
                              },
                              [
                                _c("el-input", {
                                  style: { width: "100%" },
                                  attrs: {
                                    placeholder: "请输入座机",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.formData.specialPlane,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.formData,
                                        "specialPlane",
                                        $$v
                                      )
                                    },
                                    expression: "formData.specialPlane",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 12 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "微信号", prop: "WechatId" } },
                              [
                                _c("el-input", {
                                  style: { width: "100%" },
                                  attrs: {
                                    placeholder: "请输入微信号",
                                    clearable: "",
                                  },
                                  model: {
                                    value: _vm.formData.WechatId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.formData, "WechatId", $$v)
                                    },
                                    expression: "formData.WechatId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 24 } },
                          [
                            _c(
                              "el-form-item",
                              { attrs: { label: "公众号", prop: "WechatId" } },
                              [
                                _c("span", [
                                  _vm._v(" 扫码下方二维码，获取更多资讯 "),
                                ]),
                                _c("div", { staticClass: "qrcode" }),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  { attrs: { type: "flex", justify: "center" } },
                  [
                    _c(
                      "el-button",
                      {
                        staticStyle: { margin: "0 auto" },
                        attrs: {
                          size: "mini",
                          type: "primary",
                          loading: _vm.isLoading,
                        },
                        on: { click: _vm.submitForm },
                      },
                      [_vm._v("下一步")]
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.isSubmit
          ? _c(
              "div",
              { staticClass: "content" },
              [
                _c("el-result", {
                  attrs: {
                    icon: "success",
                    title: "注册资料提交中，请耐心等待",
                    subTitle:
                      "系统正在对你的资料审核，大约需要3-15秒，请不要关闭页面",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }