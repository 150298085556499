const t = process.env.VUE_APP_TITLE|| ''
module.exports = {
  title: t,//'SIT环境',

  /**
   * @type {boolean} true | false
   * @description 是否显示设置右侧面板
   */
  showSettings: false,

  /**
   * @type {boolean} true | false
   * @description 是否需要标签查看
   */
  tagsView: true,

  /**
   * @type {boolean} true | false
   * @description 是否固定标题
   */
  fixedHeader: false,

  /**
   * @type {boolean} true | false
   * @description 是否在侧边栏中显示icon
   */
  sidebarLogo: false,

  /**
   * @type {string | array} 'production' | ['production', 'development']
   * @description 需要显示错误日志组件。默认只在生产环境中使用如果你想在开发中也使用它，你可以通过 ['production', 'development']
   */
  errorLog: 'production',

  /**
   * ASE密钥，用于敏感信息参数加密。 需要与后端保持一致
   */
  aesKey: '1b95dc4b42ca11ec926f00163e181dc2'

}
