import { constantRoutes } from '@/router'
import { getRouters } from '@/api/system/admin'
import Layout from '@/layout'

const state = {
  routes: [],
  addRoutes: [],
  indexPage:'',
}

const mutations = {
  SET_ROUTES: (state, routes) => {
    state.addRoutes = routes
    state.routes = constantRoutes.concat(routes)
  },
  SET_INDEXPAGE: (state, indexPage) => {
    state.indexPage = indexPage
  }
}

const actions = {
  generateRoutes({ commit }, roles) {
    return new Promise(resolve => {
      let accessedRoutes
      var obj = {systemPath:window.location.origin,pathName:window.location.pathname};
      getRouters(obj).then((response) => {
        const { data } = response
        accessedRoutes = [];
        var path = '0';
        if(data.length>0){
          accessedRoutes = convertRoutes(data[0].children)
          path = data[0].path;
        }
        commit('SET_ROUTES', accessedRoutes)
        commit('SET_INDEXPAGE', path)
        resolve(accessedRoutes)
      })
    })
  }
}

/**
 * 转换为vue路径
 * @param data
 */
export const convertRoutes = (data) => {
  data.forEach((item) => {
    if (item.component === 'Layout' || item.component == null) {
      item.component = Layout
    } else {
      item.component = loadView(item.component)
    }

    if (item.children != null && item.children.length > 0) {
      item.redirect = 'noRedirect'
      convertRoutes((item.children))
    } else {
      item.children = []
    }
  })
  return data
}

export const loadView = (view) => { // 路由懒加载
  return (resolve) => require([`@/views/${view}`], resolve)
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
