<template>
  <el-form ref="form" :model="form" :rules="rules">
    <el-form-item label="原密码" prop="oldPassword">
      <el-input v-model.trim="form.oldPassword" type="password" />
    </el-form-item>
    <el-form-item label="新密码" prop="newPassword">
      <el-input v-model.trim="form.newPassword" type="password" />
    </el-form-item>
    <el-form-item label="确认密码" prop="password">
      <el-input v-model.trim="form.password" type="password" />
    </el-form-item>
    <el-form-item>
      <el-button type="primary" @click.native="submit">保存</el-button>
    </el-form-item>
  </el-form>
</template>
<script>
import { updatePassword } from '@/api/system/admin'
import { mapGetters } from 'vuex'

export default {
  props: {
    user: {
      type: Object,
      default: () => {
        return {
          name: '',
          email: ''
        }
      }
    },
    callback: {
      type: Function,
      default: () => {
      }
    }
  },
  computed: {
    ...mapGetters([
      'config'
    ]),
    passwordRule() {
      debugger
      return ''
    }
  },

  data() {
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.newPassword) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }

    // 验证密码复杂度
    const validatePasswordComplexity = (rule, value, callback) => {
      const regExp = new RegExp(this.config['PASSWORD_COMPLEXITY_REGEX'])
      if (!regExp.test(this.form.newPassword)) {
        callback(new Error(this.config['PASSWORD_REGEX_ERROR_MESSAGE']))
      } else {
        callback()
      }
    }

    return {
      form: {
        oldPassword: null,
        newPassword: null,
        password: null
      },

      rules: {
        oldPassword: [{ required: true, message: '原密码不能为空', trigger: 'change' }],
        password: [
          { required: true, message: '请再次输入密码', trigger: 'change' },
          { validator: validatePass, trigger: 'change' }
        ],
        newPassword: [
          { required: true, message: '新密码不能为空', trigger: 'change' },
          { validator: validatePasswordComplexity, trigger: 'change' }
        ]
      }

    }
  },
  methods: {
    async submit() {
      let _result = true
      let _success = true
      await this.$refs.form.validate((valid) => {
        if (!valid) {
          _result = false
          return _result
        }
      })

      if (_result) {
        await updatePassword(this.form.oldPassword, this.form.password).then((response) => {
          if (response.code === 200) {
            this.$message.success('修改成功')
          } else {
            _success = false
          }
        })

        if (_success) {
          // await this.$store.dispatch('user/logout')
          // this.$router.push(`/login?redirect=${this.$route.fullPath}`)
          this.callback()
        }
      }
    }
  }
}
</script>
