import request from '@/utils/request'

/**
 * 获取枚举
 * @param className
 * @returns {AxiosPromise}
 */
export function getEnumItems(className) {
  return request({
    url: '/system/enum/getItems',
    method: 'get',
    params: { className }
  })
}

/**
 * 获取所以有枚举
 */
export function getAllEnums() {
  return request({
    url: '/system/enum/getAllEnums',
    method: 'get',
  })
}
