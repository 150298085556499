import request from '@/utils/request'

export function getConfigListApi(condition, pagination) {
  return request({
    url: '/system/config/page',
    method: 'post',
    data: { condition: condition, pagination: pagination }
  })
}

export function saveOrUpdate(data) {
  return request({
    url: '/system/config/saveOrUpdate',
    method: 'post',
    data: data
  })
}

// 获取配置
export function config() {
  return request({
    url: '/system/config/config',
    method: 'post',
  })
}

// 获取系统参数
export function pagePt(condition, pagination) {
  return request({
    url: '/system/config/pagePt',
    method: 'post',
    data: { condition: condition, pagination: pagination }
  })
}
