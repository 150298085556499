<template>
  <el-dialog
    title="请完成以下认证"
    :visible.sync="dialogVisible"
    width="25%"
    :show-close="false" center
    :close-on-press-escape="false"
    :close-on-click-modal="false">

    <el-form ref="form" :model="form" :rules="rules">

      <div style="margin-bottom: 10px">
        请输入{{ currentMobile }}收到的短信验证码
      </div>

      <el-form-item prop="code" @submit.native.prevent>
        <div style="display: flex">
          <div>
            <el-input placeholder="请输入验证码" v-model="form.code" @keyup.enter.native="submit" ></el-input>
          </div>
          <div style="margin-left: 10px;">
            <el-button v-if="disabledButton" disabled @click="">
              {{ disabledButton ? `重新获取(${countDown})` : '获取验证码' }}
            </el-button>
            <el-button v-else @click="handleSendCode">获取验证码</el-button>
          </div>
        </div>
      </el-form-item>

      <div>
        <el-button type="primary" @click="quit">更换账号</el-button>
        <el-button type="primary" @click="submit">下一步</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script>
import {mapGetters} from "vuex";
import {sendCode, validateCode} from "@/api/system/admin";

export default {
  name: 'VerifyCode',
  data() {
    return {
      dialogVisible: false,

      form: {code: null},

      rules: {
        code: [{required: true, message: '请输入验证码', trigger: 'change'}]
      },

      disabledButton: false,
      countDown: 90,

    };
  },
  methods: {

    // 发送验证码
    handleSendCode() {

      sendCode().then(() => {
        this.disabledButton = true;
        this.countDown = 90;
        let interval = setInterval(() => {
          if (this.countDown <= 0) {
            this.disabledButton = false;
            clearInterval(interval);
          }
          this.countDown--;
        }, 1000);
      });
    },

    handleCountDown() {

    },

    quit() {
      this.$store.dispatch('user/logout').then(() => {
        this.$router.push(`/login?redirect=${this.$route.fullPath}`)
      })
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return;
        }
        validateCode(this.form.code).then((response) => {
          this.dialogVisible = false;
          this.$store.commit("SET_IS_VALIDATE_CODE", false);

        });
      });

    }


  },
  mounted() {
    this.dialogVisible = this.isValidateCode;
  },
  computed: {
    currentMobile() {
      if(this.mobile!=null){
        let mobile = this.mobile;

        return mobile.replace(mobile.substr(3, 4), '****');
      }
    },

    ...mapGetters([
      'mobile', 'isValidateCode',
    ]),
  },

}
</script>

<style scoped>

</style>
