var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-card", { staticStyle: { "margin-bottom": "20px" } }, [
    _c(
      "div",
      { staticClass: "clearfix", attrs: { slot: "header" }, slot: "header" },
      [_c("span", [_vm._v("关于我")])]
    ),
    _c("div", { staticClass: "user-profile" }, [
      _c(
        "div",
        { staticClass: "box-center" },
        [
          _c(
            "el-upload",
            {
              staticClass: "avatar-uploader",
              attrs: {
                action: _vm.uploadUrl,
                "show-file-list": false,
                "on-success": _vm.handleAvatarSuccess,
              },
            },
            [
              _vm.avatar
                ? _c("img", {
                    staticClass: "avatar",
                    attrs: { src: _vm.avatar },
                  })
                : _c("i", { staticClass: "el-icon-plus avatar-uploader-icon" }),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "box-center" }, [
        _c("div", { staticClass: "user-name text-center" }, [
          _vm._v(_vm._s(_vm.user.name)),
        ]),
        _c("div", { staticClass: "user-role text-center text-muted" }, [
          _vm._v(_vm._s(_vm.user.role)),
        ]),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "user-bio" },
      [
        _c(
          "el-descriptions",
          { attrs: { title: "基本信息", column: 1 } },
          [
            _c("el-descriptions-item", { attrs: { label: "用户名" } }, [
              _vm._v(_vm._s(_vm.user.username)),
            ]),
            _c("el-descriptions-item", { attrs: { label: "手机号" } }, [
              _vm._v(_vm._s(_vm.user.mobile)),
            ]),
            _c("el-descriptions-item", { attrs: { label: "电子邮箱" } }, [
              _vm._v(_vm._s(_vm.user.email)),
            ]),
            _vm.user.accountType === "company"
              ? _c(
                  "el-descriptions-item",
                  { attrs: { label: "所属分公司" } },
                  _vm._l(_vm.user.branchOfficeList, function (item) {
                    return _c(
                      "li",
                      { staticStyle: { "list-style-type": "none" } },
                      [_vm._v(" " + _vm._s(item.name) + " ")]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.user.accountType === "site"
              ? _c(
                  "el-descriptions-item",
                  { attrs: { label: "所属网点" } },
                  _vm._l(_vm.user.branchOfficeList, function (item) {
                    return _c(
                      "li",
                      { staticStyle: { "list-style-type": "none" } },
                      [_vm._v(" " + _vm._s(item.name) + " ")]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }