import request from '@/utils/request'
//注册
export function register(data) {
    return request({
      url: '/jsb/userdata/user/saveOrUpdate',
      method: 'post',
      data
    })
  }
  //获取审批列表
  export function getUserList(data) {
    return request({
      url: '/jsb/userdata/user/list',
      method: 'post',
      data
    })
  }

    //审批用户
    export function audit(data) {
        return request({
          url: '/jsb/userdata/user/audit',
          method: 'post',
          data
        })
      }

  