<template>
  <div class="app-container">
    <div v-if="user">
      <el-row :gutter="20">
        <el-col :span="6" :xs="24">
          <user-card :user="user" />
        </el-col>
        <el-col :span="18" :xs="24">
          <el-card>
            <el-tabs v-model="activeTab">
              <el-tab-pane label="账户信息" name="account">
                <account :user="user" />
              </el-tab-pane>
              <el-tab-pane label="修改密码" name="resetPassword">
                <reset-password :user="user" />
              </el-tab-pane>
            </el-tabs>
          </el-card>
        </el-col>

      </el-row>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex'
import UserCard from './components/UserCard'
import Account from './components/Account'
import {profiles} from "@/api/system/admin";
import ResetPassword from "@/views/fwpt/profile/components/ResetPassword";

export default {
  name: 'Profile',
  components: {UserCard, Account, ResetPassword},
  data() {
    return {
      user: {},
      activeTab: 'account'
    }
  },
  computed: {
    ...mapGetters([
      'name',
      'avatar',
      'roles'
    ])
  },
  created() {
    this.getUser()
  },
  methods: {
    getUser() {
      profiles().then((response)=>{
        const {data} = response;
        this.user = data;
      })

    }
  }
}
</script>
