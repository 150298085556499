const getters = {
  sidebar: state => state.app.sidebar,
  size: state => state.app.size,
  device: state => state.app.device,
  visitedViews: state => state.tagsView.visitedViews,
  cachedViews: state => state.tagsView.cachedViews,
  token: state => state.user.token,
  avatar: state => {
    let avatar = state.user.avatar;
    if (avatar == null) {
      return 'https://cube.elemecdn.com/e/fd/0fc7d20532fdaf769a25683617711png.png'
    }
    return avatar;
  },
  userId: state => state.user.id,
  accountType: state => state.user.accountType,
  name: state => state.user.name,
  introduction: state => state.user.introduction,
  roles: state => state.user.roles,
  permission_routes: state => state.permission.routes,
  errorLogs: state => state.errorLog.logs,
  permissions: state => state.user.permissions,// 用户拥有的权限

  config: state => state.config.config,
  isNeedChangePass:state => state.user.isNeedChangePass,
  isValidateCode:state => state.user.isValidateCode,
  mobile: state => state.user.mobile


}
export default getters
