var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-form",
    { ref: "form", attrs: { model: _vm.form, rules: _vm.rules } },
    [
      _c(
        "el-form-item",
        { attrs: { label: "原密码", prop: "oldPassword" } },
        [
          _c("el-input", {
            attrs: { type: "password" },
            model: {
              value: _vm.form.oldPassword,
              callback: function ($$v) {
                _vm.$set(
                  _vm.form,
                  "oldPassword",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.oldPassword",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "新密码", prop: "newPassword" } },
        [
          _c("el-input", {
            attrs: { type: "password" },
            model: {
              value: _vm.form.newPassword,
              callback: function ($$v) {
                _vm.$set(
                  _vm.form,
                  "newPassword",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.newPassword",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        { attrs: { label: "确认密码", prop: "password" } },
        [
          _c("el-input", {
            attrs: { type: "password" },
            model: {
              value: _vm.form.password,
              callback: function ($$v) {
                _vm.$set(
                  _vm.form,
                  "password",
                  typeof $$v === "string" ? $$v.trim() : $$v
                )
              },
              expression: "form.password",
            },
          }),
        ],
        1
      ),
      _c(
        "el-form-item",
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              nativeOn: {
                click: function ($event) {
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [_vm._v("保存")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }